import React from 'react';
import pol from '../Benefit/img/польза.jpg';

function Benefit() {
  return (
    <div className='benefit'>
      <div className="container">
        <h1 className='benefit__title'>Польза нашей школы ММА</h1>
        <p className='benefit__text'>Спорт - это постоянное совершенствование себя</p>
        <div className="benefit__form">
          <img className='benefit__form-photo' src={pol} />
          <p className='benefit__form-text'>
            Регулярные физические нагрузки – это основа правильного образа жизни и залог отсутствия проблем со здоровьем в преклонном возрасте.
            Люди, которые не пренебрегают занятиями спортом, меньше болеют, поскольку их иммунитет более эффективно борется с вирусами и возбудителями различных заболеваний, редко страдают избыточным весом и лучше справляются со стрессами и житейскими проблемами. <br /> <br />
            Во время занятий спортом в организме человека происходит выработка эндорфинов, что положительно влияет на сердечно-сосудистую и нервную системы.  <br /> <br />
            Общая выносливость повышается, снижается содержание холестерина в крови, также снижается риск заболеть бронхолегочными недугами – ведь легкие у тренированных людей сильнее и выносливее.
          </p>
        </div>
      </div>
    </div>
  )
}
export default Benefit
